import { useState } from 'react';
import getUserDisplay from './getUserDisplay';
import { useChannels, useUsers } from './realmContext';
import { useSidebarSetter } from './sidebarContext';

export default function SearchBar({ className }: { className?: string }) {
  const [query, setQuery] = useState('');
  const [channel, setChannel] = useState<string>();
  const [userId, setUserId] = useState<string>();
  const setSidebar = useSidebarSetter();

  const users = useUsers();
  const channels = useChannels();

  return (
    <div className={className}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setSidebar({ type: 'search', params: { query, channel, userId } });
        }}
      >
        <select
          value={channel ?? 'ALL'}
          onChange={({ currentTarget: { value } }) =>
            setChannel(value === 'ALL' ? undefined : value)
          }
        >
          <option value="ALL">All Channels</option>
          {channels?.map((c) => (
            <option key={c} value={c}>
              {c.endsWith('league-announcements') ? '-league-announcements' : c}
            </option>
          ))}
        </select>
        <select
          value={userId ?? 'ALL'}
          onChange={({ currentTarget: { value } }) =>
            setUserId(value === 'ALL' ? undefined : value)
          }
        >
          <option value="ALL">All Users</option>
          {users.map((u) => (
            <option key={u.id} value={u.id}>
              {getUserDisplay(u)}
            </option>
          ))}
        </select>
        <input
          type="search"
          value={query}
          onChange={({ currentTarget: { value } }) => setQuery(value)}
        />
        <button type="submit">Search</button>
      </form>
    </div>
  );
}
