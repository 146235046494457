import classNames from 'classnames';
import { createContext, ReactNode, useContext, useState } from 'react';
import SearchResults from './SearchResults';
import ThreadMessages from './ThreadMessages';
import styles from './Sidebar.module.css';

export type SidebarType = 'search' | 'thread';

export type SidebarParams<T extends SidebarType> = T extends 'search'
  ? { query: string; channel?: string; userId?: string }
  : T extends 'thread'
  ? { thread_ts: string; channel: string }
  : never;

export type SidebarTypeAndParams<T extends SidebarType> = {
  type: T;
  params: SidebarParams<T>;
};

type AllSidebarTypeAndParams =
  | {
      [K in SidebarType]: SidebarTypeAndParams<K>;
    }[SidebarType]
  | { type: null; params: null };

interface SidebarConfig {
  type: SidebarType | null;
  params: unknown;
}

interface SidebarContext {
  sidebar: SidebarConfig;
  setSidebar: (params: AllSidebarTypeAndParams) => unknown;
}

export function areParamsValidForType<T extends SidebarType>(
  type: T,
  params: unknown
): params is SidebarParams<T> {
  if (typeof params !== 'object' || params == null) {
    return false;
  }
  if (type === 'search') {
    return 'query' in params && typeof (params as any).query === 'string';
  }
  if (type === 'thread') {
    return (
      'thread_ts' in params &&
      'channel' in params &&
      typeof (params as any).thread_ts === 'string' &&
      typeof (params as any).channel === 'string'
    );
  }
  return false;
}

const context = createContext<SidebarContext>({
  sidebar: { type: null, params: null },
  setSidebar: () => {},
});
const { Provider } = context;

export function useSidebarSetter() {
  const { setSidebar } = useContext(context);
  return setSidebar;
}

export function SidebarProvider({
  initConfig,
  children,
  className,
  withSidebarClassName,
}: {
  initConfig?: AllSidebarTypeAndParams | null;
  children: ReactNode;
  className?: string;
  withSidebarClassName?: string;
}) {
  const [sidebar, setSidebar] = useState<SidebarConfig>(
    initConfig ?? { type: null, params: null }
  );

  return (
    <Provider value={{ sidebar, setSidebar }}>
      <div
        className={classNames(
          className,
          withSidebarClassName == null
            ? null
            : { [withSidebarClassName]: sidebar.type != null }
        )}
      >
        {children}
      </div>
    </Provider>
  );
}

export function Sidebar({ className }: { className?: string }) {
  const sidebarConfig = useContext(context).sidebar;
  const setSidebar = useSidebarSetter();
  if (sidebarConfig.type == null) {
    return null;
  }
  const closeButton = (
    <button
      type="button"
      className={styles.close}
      onClick={() => setSidebar({ type: null, params: null })}
    >
      &times;
    </button>
  );
  const { params } = sidebarConfig;
  if (sidebarConfig.type === 'search') {
    if (!areParamsValidForType(sidebarConfig.type, params)) {
      console.error('Invalid params for type', sidebarConfig);
      throw new Error('Invalid params for type ' + sidebarConfig.type);
    }
    return (
      <div className={classNames(styles.sidebar, className)}>
        {closeButton}
        <SearchResults {...params} />
      </div>
    );
  } else if (sidebarConfig.type === 'thread') {
    if (!areParamsValidForType(sidebarConfig.type, params)) {
      console.error('Invalid params for type', sidebarConfig);
      throw new Error('Invalid params for type ' + sidebarConfig.type);
    }
    return (
      <div className={classNames(styles.sidebar, className)}>
        {closeButton}
        <ThreadMessages {...params} />
      </div>
    );
  }
  return null;
}
