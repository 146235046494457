import { useEffect, useState } from 'react';

export default function useAsyncValue<T>(fn: () => Promise<T>): T | null {
  const [value, setValue] = useState<T | null>(null);

  useEffect(() => {
    let cancelled = false;

    fn().then((val) => {
      if (cancelled) {
        return;
      }
      setValue(val);
    });

    return () => {
      setValue(null);
      cancelled = true;
    };
  }, [fn]);

  return value;
}
