import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import groupBy from 'lodash.groupby';
import { useRealmUser } from './realmContext';
import MessageDisplay from './MessageDisplay';
import type { message as Message } from './schemaTypes';

import styles from './Messages.module.css';

const MAX_NUM_MESSAGES_RETURNED = 500;

export default function Messages({
  className,
  channel,
  centerOn,
}: {
  className?: string;
  channel: string;
  centerOn?: { user: string; ts: string };
}) {
  const user = useRealmUser();
  const [messages, setMessages] = useState<Message[] | null>(null);
  const [canLoadMore, setCanLoadMore] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  useEffect(() => {
    setMessages(null);
  }, [channel]);

  useEffect(() => {
    if (user == null) {
      return;
    }
    if (messages != null) {
      return;
    }

    user.functions
      .getMessages({ channel })
      .then((m) =>
        m.sort((a, b) => a.datetime.getTime() - b.datetime.getTime())
      )
      .then((messages) => {
        setMessages(messages);
        if (messages.length === MAX_NUM_MESSAGES_RETURNED) {
          setCanLoadMore(true);
        }
      });
  }, [user, messages, channel]);

  const groupedMessages = useMemo(
    () =>
      groupBy(
        messages,
        ({ datetime }) =>
          `${datetime.getFullYear()}${(datetime.getMonth() + 1)
            .toString()
            .padStart(2, '0')}${datetime.getDate().toString().padStart(2, '0')}`
      ),
    [messages]
  );

  const orderedMessageGroupKeys = useMemo(
    () => Object.keys(groupedMessages).sort(),
    [groupedMessages]
  );

  const loadMore = useCallback(() => {
    if (!canLoadMore || messages == null || user == null) {
      return;
    }
    const firstMessage = messages[0];

    setIsLoadingMore(true);

    user.functions
      .getMessages({ channel, before: firstMessage.datetime })
      .then((m) =>
        m.sort((a, b) => a.datetime.getTime() - b.datetime.getTime())
      )
      .then((newMessages) => {
        setMessages((prev) =>
          prev == null ? newMessages : [...newMessages, ...prev]
        );
        setCanLoadMore(newMessages.length === MAX_NUM_MESSAGES_RETURNED);
        setIsLoadingMore(false);
      });
  }, [canLoadMore, messages, user, channel]);

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.spacer} />
      {canLoadMore && (
        <button
          disabled={isLoadingMore}
          className={styles.loadMore}
          onClick={loadMore}
        >
          Load More
        </button>
      )}
      {messages == null ? (
        <div className={styles.loading}>Loading messages</div>
      ) : (
        orderedMessageGroupKeys.map((key) => (
          <div key={key}>
            <h3 className={styles.dateHeader}>{key}</h3>
            {groupedMessages[key].map((message) => (
              <MessageDisplay
                key={message.client_msg_id ?? `${message.ts}_${message.user}`}
                type={message.type}
                subtype={message.subtype}
                text={message.text ?? ''}
                blocks={message.blocks}
                isBot={
                  (message.display_as_bot ||
                    message.subtype === 'bot_message') ??
                  false
                }
                userId={message.bot_id ?? message.user ?? ''}
                replyCount={message.reply_count}
                channel={channel}
                thread_ts={message.thread_ts}
              />
            ))}
          </div>
        ))
      )}
    </div>
  );
}
