import {
  useChannelNameById,
  useIndexedUsers,
  useUserById,
} from './realmContext';
import styles from './Messages.module.css';
import { useSidebarSetter } from './sidebarContext';
import {
  message_blocks as Block,
  message_blocks_elements as BlockElement,
  message_blocks_elements_elements as BlockElementElement,
  message_blocks_elements_elements_elements as BlockElementElementElement,
} from './schemaTypes';
import getUserDisplay from './getUserDisplay';
import classNames from 'classnames';
import { useMemo } from 'react';

function UserDisplay({ userId }: { userId: string }) {
  const user = useUserById(userId);
  return (
    <span className={styles.userTag}>
      @{user == null ? userId : getUserDisplay(user)}
    </span>
  );
}

function ChannelJoinMessage({ text }: { text: string }) {
  const match = text.match(/<@([^>]*)>(.*)/);
  if (match == null) {
    return <>{text}</>;
  }
  return (
    <>
      <UserDisplay userId={match[1]} />
      {match[2]}
    </>
  );
}

function ChannelDisplay({ channelId }: { channelId: string }) {
  const channelName = useChannelNameById(channelId);
  return <span className={styles.channelTag}>#{channelName ?? channelId}</span>;
}

type AnyBlockLevel =
  | Block
  | BlockElement
  | BlockElementElement
  | BlockElementElementElement;
type AnyBlockType = NonNullable<AnyBlockLevel['type']>;

type SupportedBlockType =
  | 'rich_text'
  | 'rich_text_section'
  | 'rich_text_quote'
  | 'section'
  | 'text'
  | 'mrkdwn'
  | 'emoji'
  | 'user'
  | 'link'
  | 'channel';

function isSupportedBlockType(type: AnyBlockType): type is SupportedBlockType {
  return (
    type === 'rich_text' ||
    type === 'rich_text_section' ||
    type === 'rich_text_quote' ||
    type === 'section' ||
    type === 'text' ||
    type === 'mrkdwn' ||
    type === 'emoji' ||
    type === 'user' ||
    type === 'link' ||
    type === 'channel'
  );
}

function BlockDisplay({ block }: { block: AnyBlockLevel }) {
  const fallback = 'fallback' in block ? <>{block.fallback ?? null}</> : null;
  if (block.type == null) {
    return fallback;
  }
  if (!isSupportedBlockType(block.type)) {
    return (
      fallback ?? (
        <span className={styles.notSupportedBlock}>{block.type} block</span>
      )
    ); // TODO
  }
  if (block.type === 'text') {
    const { style } = block as {
      style?: { bold?: boolean; italic?: boolean; strike?: true };
    };
    return (
      <span
        className={classNames({
          [styles.bold]: style?.bold,
          [styles.italic]: style?.italic,
          [styles.strike]: style?.strike,
        })}
      >
        {block.text ?? null}
      </span>
    );
  }
  if (block.type === 'rich_text' || block.type === 'rich_text_section') {
    if (block.elements == null) {
      return null;
    }
    return (
      <>
        {block.elements.map((b, idx) => (
          <BlockDisplay key={idx} block={b} />
        ))}
      </>
    );
  }
  if (block.type === 'rich_text_quote') {
    if (block.elements == null) {
      return null;
    }
    return (
      <div className={styles.quoteBlock}>
        {block.elements.map((b, idx) => (
          <BlockDisplay key={idx} block={b} />
        ))}
      </div>
    );
  }
  if (block.type === 'section') {
    // TODO: Parse markdown
    return <>{block.text?.text ?? null}</>;
  }
  if (block.type === 'mrkdwn') {
    // TODO: Parse markdown
    return <>{block.text ?? null}</>;
  }
  if (block.type === 'emoji') {
    return (
      <span className={styles.emoji}>:{(block as any).name ?? null}:</span>
    );
  }
  if (block.type === 'user') {
    const { user_id } = block as { user_id: string };
    return <UserDisplay userId={user_id} />;
  }
  if (block.type === 'link') {
    const { url, text } = block as { url: string; text: string };
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );
  }
  if (block.type === 'channel') {
    return <ChannelDisplay channelId={block.channel_id!} />;
  }
  return null;
}

function TextParser({ text }: { text: string }) {
  const usersById = useIndexedUsers();

  const withUserNames = useMemo(
    () =>
      text.replaceAll(/<@([^>]*)>/g, (match, id: string) => {
        const user = usersById[id];
        return user == null ? match : `@${getUserDisplay(usersById[id])}`;
      }),
    [text, usersById]
  );

  return <>{withUserNames}</>;
}

export default function MessageDisplay({
  text,
  blocks,
  type,
  subtype,
  userId,
  isBot,
  replyCount = 0,
  channel,
  thread_ts,
}: {
  text: string;
  blocks?: Block[];
  type?: string;
  subtype?: string;
  /** Can be either a slack user id or a bot id */
  userId: string;
  isBot: boolean;
  replyCount?: number;
  channel: string;
  thread_ts?: string;
}) {
  const user = useUserById(userId);
  const setSidebar = useSidebarSetter();

  return (
    <div className={styles.message}>
      <span className={styles.messageSender}>
        <span className={styles.user} title={user?.real_name}>
          {user == null ? (
            <span className={styles.unknownUser}>Unknown user</span>
          ) : (
            getUserDisplay(user)
          )}
        </span>
        {isBot ? ' (bot)' : ''}:{' '}
      </span>
      {type === 'message' && subtype === 'channel_join' ? (
        <ChannelJoinMessage text={text} />
      ) : (
        <div className={styles.messageBody}>
          {blocks?.map((block) => (
            <BlockDisplay key={block.block_id} block={block} />
          )) ?? <TextParser text={text} />}
        </div>
      )}
      {replyCount === 0 || thread_ts == null ? null : (
        <button
          type="button"
          onClick={() =>
            setSidebar({ type: 'thread', params: { thread_ts, channel } })
          }
        >
          View {replyCount} repl{replyCount === 1 ? 'y' : 'ies'}
        </button>
      )}
    </div>
  );
}
