import { ObjectId } from 'bson';
import {
  useRef,
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import * as Realm from 'realm-web';
import type { message as Message, user as User } from './schemaTypes';
import useAsyncValue from './useAsyncValue';

type RealmFunctions = {
  getChannelNames: () => Promise<string[]>;
  getMessages: (params: {
    channel: string;
    before?: Date;
  }) => Promise<Message[]>;
  getThreadMessages: (params: {
    channel: string;
    thread_ts: string;
  }) => Promise<Message[]>;
  searchMessages: (params: {
    query: string;
    channel?: string;
    userId?: string;
  }) => Promise<Message[]>;
};

const slackbotUser: User = {
  id: 'USLACKBOT',
  _id: new ObjectId(), // Just to make TS happy
  real_name: 'Slackbot',
  profile: {
    display_name_normalized: 'Slackbot',
  },
};

type RealmUser = Realm.User<RealmFunctions>;
type RealmApp = Realm.App<RealmFunctions>;

interface RealmContext {
  app: RealmApp | null;
  user: RealmUser | null;
  usersById: Record<string, User>;
  channelNamesById: Record<string, string>;
  slackUsers: readonly User[];
}

const context = createContext<RealmContext>({
  app: null,
  user: null,
  usersById: {},
  channelNamesById: {},
  slackUsers: [],
});
const { Provider } = context;

export function useChannels() {
  const { user } = useContext(context);

  return useAsyncValue<string[] | null>(
    useCallback(async () => {
      if (user == null) {
        return null;
      }
      return (await user.functions.getChannelNames()).sort((a, b) => {
        if (a.endsWith('announcements')) {
          return -1;
        }
        if (b.endsWith('announcements')) {
          return 1;
        }
        if (a < b) {
          return -1;
        } else if (a === b) {
          return 0;
        }
        return 1;
      });
    }, [user])
  );
}

export function useIndexedUsers() {
  const { usersById } = useContext(context);
  return usersById;
}

export function useUserById(userId: string) {
  return useIndexedUsers()[userId] ?? null;
}

export function useUsers() {
  return useContext(context).slackUsers;
}

export function useIndexedChannelNames() {
  return useContext(context).channelNamesById;
}

export function useChannelNameById(channelId: string) {
  return useIndexedChannelNames()[channelId] ?? null;
}

export function useRealmUser() {
  return useContext(context).user;
}

export function RealmProvider({ children }: { children: ReactNode }) {
  const appRef = useRef<RealmApp>();
  const credsRef = useRef<Realm.Credentials>();
  const [user, setUser] = useState<RealmUser | null>(null);
  const userRequestInProgressRef = useRef(false);

  if (appRef.current == null) {
    appRef.current = new Realm.App(process.env.REACT_APP_REALM_APPID!);
  }

  if (credsRef.current == null) {
    credsRef.current = Realm.Credentials.anonymous();
  }

  const app = appRef.current;
  const creds = credsRef.current;

  useEffect(() => {
    if (user != null || userRequestInProgressRef.current) {
      return;
    }
    userRequestInProgressRef.current = true;
    app.logIn(creds).then((u) => {
      setUser(u);
    });
  });

  const slackUsers = useAsyncValue(
    useCallback(() => {
      if (user == null) {
        return Promise.resolve(null);
      }

      return user
        .mongoClient('mongodb-atlas')
        .db('slack')
        .collection<User>('users')
        .find();
    }, [user])
  );

  const slackChannels = useAsyncValue(
    useCallback(() => {
      if (user == null) {
        return Promise.resolve(null);
      }

      return user
        .mongoClient('mongodb-atlas')
        .db('slack')
        .collection<{ _id: string; id: string; name: string }>('channels')
        .find();
    }, [user])
  );

  const usersById = useMemo(
    () =>
      [...(slackUsers ?? []), slackbotUser].reduce<Record<string, User>>(
        (acc, user) => {
          if (user.is_bot && user.profile?.bot_id != null) {
            acc[user.profile.bot_id] = user;
          }
          acc[user.id] = user;
          return acc;
        },
        {}
      ),
    [slackUsers]
  );

  const channelNamesById = useMemo(
    () =>
      slackChannels?.reduce<Record<string, string>>((acc, { id, name }) => {
        acc[id] = name;
        return acc;
      }, {}) ?? {},
    [slackChannels]
  );

  const contextValue: RealmContext = useMemo(
    () => ({
      app,
      user,
      usersById,
      channelNamesById,
      slackUsers: slackUsers ?? [],
    }),
    [app, user, usersById, channelNamesById, slackUsers]
  );

  return <Provider value={contextValue}>{children}</Provider>;
}
