import { useEffect, useState } from 'react';
import MessageDisplay from './MessageDisplay';
import { useRealmUser } from './realmContext';
import { message as Message } from './schemaTypes';

export default function SearchResults({
  className,
  query,
  channel,
  userId,
}: {
  className?: string;
  query: string;
  channel?: string;
  userId?: string;
}) {
  const user = useRealmUser();
  const [messages, setMessages] = useState<Message[]>();

  useEffect(() => {
    if (user == null) {
      return;
    }
    user.functions.searchMessages({ query, channel, userId }).then(setMessages);
  }, [user, query, channel, userId]);

  return (
    <div className={className}>
      {messages?.map((message) => (
        <MessageDisplay
          key={message.client_msg_id ?? `${message.ts}_${message.user}`}
          text={message.text ?? ''}
          isBot={
            (message.display_as_bot || message.subtype === 'bot_message') ??
            false
          }
          userId={message.bot_id ?? message.user ?? ''}
          replyCount={message.reply_count}
          channel={message.channel ?? ''}
          thread_ts={message.thread_ts}
        />
      ))}
    </div>
  );
}
