import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './App.css';
import MessageViewer from './MessageViewer';

export default function App() {
  return (
    <>
      <Helmet
        titleTemplate="%s | IBWDL Slack Archive"
        defaultTitle="IBWDL Slack Archive"
      />
      <Router>
        <Switch>
          <Route
            path="/channel/:channel"
            render={({ match }) => (
              <MessageViewer channel={match.params.channel} />
            )}
          />
          <Route path="/" exact>
            <Redirect to="/channel/general" />
          </Route>
        </Switch>
      </Router>
    </>
  );
}
