import { NavLink } from 'react-router-dom';
import { useChannels } from './realmContext';

import styles from './ChannelList.module.css';
import classNames from 'classnames';

export default function ChannelList({
  selected,
  className,
}: {
  selected: string;
  className?: string;
}) {
  const channelList = useChannels();

  if (channelList == null) {
    return null; // TODO: Loading state
  }

  return (
    <ul className={classNames(className, styles.list)}>
      {channelList.map((channel) => (
        <li key={channel}>
          <NavLink
            to={`/channel/${channel}`}
            className={styles.link}
            activeClassName={styles.selected}
          >
            {channel.endsWith('league-announcements')
              ? '-league-announcements'
              : channel}
          </NavLink>
        </li>
      ))}
    </ul>
  );
}
