import { user as User } from './schemaTypes';

export default function getUserDisplay(user: User): string {
  return (
    user.profile?.display_name_normalized ||
    user.real_name ||
    user.profile?.real_name_normalized ||
    user.id
  );
}
