import { Helmet } from 'react-helmet';
import ChannelList from './ChannelList';
import Messages from './Messages';
import styles from './MessageViewer.module.css';
import SearchBar from './SearchBar';
import { SidebarProvider, Sidebar } from './sidebarContext';

interface MessageViewerProps {
  channel: string;
}

export default function MessageViewer(props: MessageViewerProps) {
  const { channel } = props;
  return (
    <SidebarProvider
      className={styles.grid}
      withSidebarClassName={styles.gridWithSidebar}
    >
      <Helmet title={`#${channel}`} />
      <ChannelList className={styles.channels} selected={channel} />
      <SearchBar className={styles.search} />
      <Messages className={styles.messages} channel={channel} />
      <Sidebar className={styles.sidebar} />
    </SidebarProvider>
  );
}
