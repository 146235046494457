import { useCallback } from 'react';
import MessageDisplay from './MessageDisplay';
import { useRealmUser } from './realmContext';
import useAsyncValue from './useAsyncValue';

export default function ThreadMessages({
  className,
  channel,
  thread_ts,
}: {
  className?: string;
  channel: string;
  thread_ts: string;
}) {
  const user = useRealmUser();
  const messages = useAsyncValue(
    useCallback(async () => {
      if (user == null) {
        return null;
      }
      return await user.functions.getThreadMessages({ channel, thread_ts });
    }, [user, channel, thread_ts])
  );
  return (
    <div className={className}>
      {messages == null
        ? 'Loading...'
        : messages.map((message) => (
            <MessageDisplay
              key={message.client_msg_id ?? message.ts}
              type={message.type}
              subtype={message.subtype}
              text={message.text ?? ''}
              blocks={message.blocks}
              channel={channel}
              isBot={
                (message.display_as_bot || message.subtype === 'bot_message') ??
                false
              }
              userId={message.bot_id ?? message.user ?? ''}
            />
          ))}
    </div>
  );
}
